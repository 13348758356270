/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Precios de artículos </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}. Los
      precios finales mostrados no contienen
      <span class="global-form-hint-color"> impuestos</span>.
    </div>
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.importPricesListsArticles === 'S' &&
            permissions &&
            permissions.main.importPricesListsArticles === 'S'
          "
          >Importar precios de artículos</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.exportPricesListsArticles === 'S' &&
            permissions &&
            permissions.main.exportPricesListsArticles === 'S'
          "
          >Exportar precios de artículos</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startExportFullArticle()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.exportPricesListsArticles === 'S' &&
            permissions &&
            permissions.main.exportPricesListsArticles === 'S'
          "
          >Exportar precios de artículos en columnas</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="refreshInfo()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Artículos: <b>{{ filteredArticles.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="filteredArticles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedArticles"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Articles"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Precios de ${row.CLAVE_ART}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      scrollable
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      checkbox-position="left"
      default-sort="STATUS"
    >
      <template #empty>
        <div class="has-text-centered">No hay articulos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span v-if="checkIfValid(props.row[column.field])">
            {{ props.row[column.field] }}</span
          >
        </b-table-column>
        <b-table-column
          v-for="column in priceListsColumns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span v-if="checkIfValid(props.row[column.field])">
            {{ props.row[column.field] | money("MXN", 2) }}</span
          >
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <p
              v-if="
                !companyPaymentPlanModules ||
                companyPaymentPlanModules.sales.getPriceListsMargin !== 'S' ||
                !permissions ||
                permissions.sales.getPriceListsMargin !== 'S'
              "
            >
              No hay información que mostrar
            </p>

            <div
              v-if="
                companyPaymentPlanModules &&
                companyPaymentPlanModules.sales.getPriceListsMargin === 'S' &&
                permissions &&
                permissions.sales.getPriceListsMargin === 'S'
              "
            >
              <b-field
                label="Costo máximo de compra (Requerido)"
                expanded
                label-position="inside"
                class="special-margin required-input"
                required
                v-if="
                  companyPaymentPlanModules &&
                  companyPaymentPlanModules.purchase
                    .getArticleCostInformation === 'S' &&
                  permissions &&
                  permissions.purchase.getArticleCostInformation === 'S'
                "
              >
                <MoneyInputBlur
                  currency="MXN"
                  :digits="5"
                  placeholder="Ejemplo: 16"
                  icon="cash-multiple"
                  v-model="props.row.COSTO_MIN_VTA"
                  :required="true"
                  disabled
                >
                </MoneyInputBlur>
              </b-field>

              <div :key="forceRendering">
                <div
                  v-for="(singleList, index) in props.row.defaultListPrices"
                  v-bind:key="singleList._id"
                  class="special-big-margin single-articles-prices"
                >
                  <h3 class="title-expandable-item">
                    <strong>Precios de lista </strong>
                    <strong
                      ><span class="global-form-hint-color">{{
                        singleList.NOMBRE_LISTAPT
                      }}</span></strong
                    >
                  </h3>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Porcentaje de utilidad (Requerido)"
                      expanded
                      label-position="inside"
                      v-if="
                        companyPaymentPlanModules &&
                        companyPaymentPlanModules.sales.getPriceListsMargin ===
                          'S' &&
                        permissions &&
                        permissions.sales.getPriceListsMargin === 'S'
                      "
                    >
                      <PercentageInput
                        placeholder="Ejemplo: 40"
                        expanded
                        icon="numeric"
                        v-model="singleList.PORC_UTILID"
                        type="number"
                        step="any"
                        required
                        @input="
                          getFinalPrice(
                            props.row,
                            singleList.PORC_UTILID,
                            true,
                            index
                          )
                        "
                      />
                    </b-field>

                    <b-field
                      label="Precio final (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <MoneyInput
                        currency="MXN"
                        :digits="5"
                        placeholder="Ejemplo: 5000"
                        icon="clipboard"
                        v-model="singleList.calculatedPrice"
                        :required="true"
                        :disabled="false"
                        @change.native="
                          adjustPrice($event, props.row, singleList)
                        "
                      >
                      </MoneyInput>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Precio final con descuentos, indirectos e impuestos (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <MoneyInput
                        currency="MXN"
                        :digits="5"
                        placeholder="Ejemplo: 5000"
                        icon="clipboard"
                        :value="getFinalTaxPrice(props.row, singleList)"
                        :disabled="true"
                      >
                      </MoneyInput>
                    </b-field>
                  </b-field>

                  <div class="controls special-margin">
                    <b-button
                      type="is-primary"
                      class="special-margin"
                      @click="openPriceListDicounts(props.row, singleList)"
                      >Ver descuento</b-button
                    >
                    <b-button
                      type="is-primary"
                      class="special-margin"
                      @click="openPriceListCosts(props.row, singleList)"
                      >Ver indirectos</b-button
                    >
                  </div>

                  <div
                    v-for="singlePresentation in props.row.PRESENTACIONES"
                    v-bind:key="singlePresentation._id"
                  >
                    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                    <div>
                      <h3
                        class="title-expandable-item"
                        v-if="singlePresentation.UNIDAD_EMP"
                      >
                        <strong>Presentación </strong>
                        <strong
                          ><span class="global-form-hint-color">{{
                            singlePresentation.NOMBRE
                          }}</span>
                        </strong>
                      </h3>

                      <h3
                        class="title-expandable-item"
                        v-if="!singlePresentation.UNIDAD_EMP"
                      >
                        <strong>Presentación </strong>
                        <strong
                          ><span class="global-form-hint-color">{{
                            singlePresentation.NOMBRE
                          }}</span>
                        </strong>
                      </h3>

                      <b-field grouped class="special-margin">
                        <b-field
                          label="Valor de medida base del artículo (Requerido)"
                          expanded
                          label-position="inside"
                        >
                          <b-input
                            placeholder="Ejemplo: 40"
                            expanded
                            icon="numeric-1-circle-outline"
                            type="number"
                            step="any"
                            :value="singlePresentation.FACTOR1"
                            disabled
                          ></b-input>
                        </b-field>

                        <b-field
                          label="Equivalencia en medidas base (Requerido)"
                          expanded
                          label-position="inside"
                        >
                          <b-input
                            placeholder="Ejemplo: 40"
                            expanded
                            icon="numeric-2-circle-outline"
                            type="number"
                            step="any"
                            :value="singlePresentation.FACTOR2"
                            disabled
                          ></b-input>
                        </b-field>
                      </b-field>

                      <b-field grouped class="special-margin">
                        <b-field
                          label="Porcentaje de utilidad (Requerido)"
                          expanded
                          label-position="inside"
                          v-if="
                            companyPaymentPlanModules &&
                            companyPaymentPlanModules.sales
                              .getPriceListsMargin === 'S' &&
                            permissions &&
                            permissions.sales.getPriceListsMargin === 'S'
                          "
                        >
                          <PercentageInput
                            placeholder="Ejemplo: 40"
                            expanded
                            icon="numeric"
                            v-model="
                              singlePresentation.defaultListPrices[index]
                                .PORC_UTILID
                            "
                            type="number"
                            step="any"
                            required
                            @input="
                              getFinalPrice(
                                props.row,
                                singlePresentation.defaultListPrices[index]
                                  .PORC_UTILID,
                                true,
                                index,
                                false,
                                singlePresentation
                              )
                            "
                          />
                        </b-field>

                        <b-field
                          label="Precio final"
                          expanded
                          label-position="inside"
                        >
                          <MoneyInput
                            currency="MXN"
                            :digits="5"
                            placeholder="Ejemplo: 5000"
                            icon="clipboard"
                            v-model="
                              singlePresentation.defaultListPrices[index]
                                .calculatedPrice
                            "
                            :required="true"
                            :disabled="false"
                            @change.native="
                              adjustPrice(
                                $event,
                                props.row,
                                singlePresentation.defaultListPrices[index],
                                false,
                                singlePresentation
                              )
                            "
                          >
                          </MoneyInput>
                        </b-field>
                      </b-field>

                      <b-field grouped class="special-margin">
                        <b-field
                          label="Precio final con descuentos, indirectos e impuestos (Requerido)"
                          expanded
                          label-position="inside"
                        >
                          <MoneyInput
                            currency="MXN"
                            :digits="5"
                            placeholder="Ejemplo: 5000"
                            icon="clipboard"
                            :value="
                              getFinalTaxPrice(
                                props.row,
                                singlePresentation.defaultListPrices[index]
                              )
                            "
                            :required="false"
                            :disabled="true"
                          >
                          </MoneyInput>
                        </b-field>
                      </b-field>
                    </div>

                    <div class="controls special-margin">
                      <b-button
                        type="is-primary"
                        class="special-margin"
                        v-if="
                          companyPaymentPlanModules &&
                          companyPaymentPlanModules.sales
                            .savePricesListDiscounts === 'S' &&
                          permissions &&
                          permissions.sales.savePricesListDiscounts === 'S'
                        "
                        @click="
                          openPriceListDicounts(
                            props.row,
                            singlePresentation.defaultListPrices[index],
                            singlePresentation
                          )
                        "
                        >Ver descuento</b-button
                      >
                      <b-button
                        type="is-primary"
                        class="special-margin"
                        v-if="
                          companyPaymentPlanModules &&
                          companyPaymentPlanModules.sales
                            .savePricesListCosts === 'S' &&
                          permissions &&
                          permissions.sales.savePricesListCosts === 'S'
                        "
                        @click="
                          openPriceListCosts(
                            props.row,
                            singlePresentation.defaultListPrices[index],
                            singlePresentation
                          )
                        "
                        >Ver indirectos</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="savePricesChanges(props.row)"
                  >Guardar cambios</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import ImportFileModal from "../../Global/components/ImportFileModal";
import AddDiscount from "../../Purchases/components/purchase-orders-actions/AddDiscount.vue";
import AddCost from "../../Purchases/components/purchase-orders-actions/AddCost.vue";
import moment from "moment";
import Vue from "vue";
import { round } from "../../../utils/fns";

export default {
  name: "PricesListsArticles",
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedArticles: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      permitedColumns: {
        status: {
          field: "STATUS",
          label: "Status",
          sortable: true,
          searchable: true,
        },
      },
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "totalQuantity",
          label: "Inventario total",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      priceListsColumns: [],
      filterProvider: "",
      forceRendering: 0,
      refreshLoading: false,
    };
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "pricesArticlesLists");

    let columnsToAdd = [];
    for (const singleList of this.priceLists) {
      columnsToAdd.push({
        field: `lista_precios_${singleList.CLAVE_LISTAP}`,
        label: singleList.CLAVE_LISTAP,
        sortable: true,
        searchable: true,
        display: true,
      });
    }
    this.priceListsColumns = columnsToAdd;
  },
  async created() {
    moment.locale("es");
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETPROVIDERS"));
    allPromises.push(this.$store.dispatch("GETBRANDS"));

    await Promise.all(allPromises);
  },
  methods: {
    async refreshInfo() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
      allPromises.push(this.$store.dispatch("GETARTICLES"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETBRANDS"));
      allPromises.push(this.$store.dispatch("GETCATEGORIES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    confirmExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de proveedores",
        message: `¿Desea confirmar la exportación de los proveedores a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "priceListsArticles",
          nameImport: "Precios de artículos",
          afterAction: "GETARTICLES",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     * @desc send the object to change to the backend
     */
    obtainChangesInLists(priceInformation) {
      let changePricesInformation = {
        COSTO_MIN_VTA: Number(priceInformation.COSTO_MIN_VTA),
      };
      let changedPrices = [];
      // Main article list of prices
      for (const singleDefaultList of priceInformation.defaultListPrices) {
        let priceListobject = { ...singleDefaultList };
        priceListobject.CLAVE_ART = priceInformation._id;
        priceListobject.mainArticle = true;
        priceListobject.PORC_UTILID = Number(priceListobject.PORC_UTILID);
        changedPrices.push(priceListobject);
      }
      // Presentation list of prices
      if (priceInformation.PRESENTACIONES) {
        for (let singlePresentation of priceInformation.PRESENTACIONES) {
          for (let singlePricePresentation of singlePresentation.defaultListPrices) {
            let objectPriceListobject = { ...singlePricePresentation };
            objectPriceListobject.CLAVE_ART = priceInformation._id;
            objectPriceListobject.CLAVE_PRESEN = singlePresentation._id;
            objectPriceListobject.mainArticle = false;
            objectPriceListobject.PORC_UTILID = Number(
              objectPriceListobject.PORC_UTILID
            );
            changedPrices.push(objectPriceListobject);
          }
        }
      }

      changePricesInformation.changedPrices = changedPrices;
      changePricesInformation.articleId = priceInformation._id;
      return changePricesInformation;
    },
    async savePricesChanges(priceInformation) {
      try {
        let newChanges = this.obtainChangesInLists(priceInformation);
        let response = await this.$store.dispatch(
          "UPDATEPRICELISTARTICLES",
          newChanges
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se guardó exitosamente los cambios de " +
              priceInformation.CLAVE_ART,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar los cambios de " +
              priceInformation.CLAVE_ART,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar los cambios de " +
            priceInformation.CLAVE_ART,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    adjustPrice(
      event,
      articleInfo,
      singleList,
      onlyMainArticle = true,
      presentation = undefined
    ) {
      let adjustedPrice = event.target.value;
      adjustedPrice = adjustedPrice.replace("$", "");
      adjustedPrice = adjustedPrice.replace(",", "");
      adjustedPrice = Number(adjustedPrice);
      // Add the Merma to the calculation
      if (articleInfo.PORC_MERMA)
        adjustedPrice +=
          articleInfo.COSTO_MIN_VTA * (articleInfo.PORC_MERMA / 100);
      // Add other cost to the calculation
      if (articleInfo.PORC_INDIREC)
        adjustedPrice +=
          articleInfo.COSTO_MIN_VTA * (articleInfo.PORC_INDIREC / 100);
      // Add shipping cost to the calculation
      if (articleInfo.PORC_FLETE)
        adjustedPrice +=
          articleInfo.COSTO_MIN_VTA * (articleInfo.PORC_FLETE / 100);

      //Only when changing article information
      if (onlyMainArticle) {
        let adjustedMargin =
          (adjustedPrice * 100) / articleInfo.COSTO_MIN_VTA - 100;
        singleList.PORC_UTILID = adjustedMargin.toFixed(5);
      }

      if (!onlyMainArticle) {
        if (presentation.ESMAYOR === "S") {
          adjustedPrice = adjustedPrice / presentation.FACTOR2;
        } else {
          adjustedPrice = adjustedPrice * presentation.FACTOR2;
        }

        let adjustedMargin =
          (adjustedPrice * 100) / articleInfo.COSTO_MIN_VTA - 100;
        singleList.PORC_UTILID = adjustedMargin.toFixed(5);
        singleList.calculatedPrice = event.target.value
          .replace("$", "")
          .replace(",", "");

        this.forceRendering += 1;
      }
    },
    /**
     *
     * @param currentArticle current article to get the minimum sale cost
     * @param utility the utility to use
     * @param change if set to true this will change the article object
     * @param index the index of the price list
     * @param onlyMainArticle if its the main article or a presentation
     * @param presentation the presentation info
     * @returns {string} the final price calculation
     */
    getFinalPrice(
      currentArticle,
      utility,
      change = false,
      index,
      onlyMainArticle = true,
      presentation = undefined
    ) {
      let finalMinimumCost = 0;
      let IVAQuantity = 0;
      let IEPSQuantity = 0;
      if (currentArticle.COSTO_MIN_VTA) {
        if (onlyMainArticle) {
          // Add the utility margin to the calculation for article
          let { COSTO_MIN_VTA } = { ...currentArticle };
          finalMinimumCost =
            Number(COSTO_MIN_VTA) + COSTO_MIN_VTA * (utility / 100);

          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
        } else {
          // Add the utility margin to the calculation for presentation
          let { COSTO_MIN_VTA } = { ...currentArticle };
          let { COSTO_MIN_VTA_PRESENT } = { ...currentArticle };
          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
          if (presentation.ESMAYOR === "S") {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA * presentation.FACTOR2;
          } else {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA / presentation.FACTOR2;
          }
          finalMinimumCost +=
            Number(COSTO_MIN_VTA_PRESENT) +
            COSTO_MIN_VTA_PRESENT * (utility / 100);
        }
      }
      if (change) {
        if (!onlyMainArticle) {
          for (const [
            singlePresentationIndex,
            singlePresentation,
          ] of currentArticle.PRESENTACIONES.entries()) {
            if (singlePresentation._id === presentation._id) {
              currentArticle.PRESENTACIONES[
                singlePresentationIndex
              ].defaultListPrices[index].calculatedPrice =
                Number(finalMinimumCost).toFixed(5);
            }
          }

          let currentArticlePriceList = currentArticle.defaultListPrices;
          Vue.set(currentArticlePriceList, index, {
            ...currentArticlePriceList[index],
          });
        } else {
          currentArticle.defaultListPrices[index].calculatedPrice =
            Number(finalMinimumCost).toFixed(5);
        }
      }
      return Number(finalMinimumCost.toString()).toFixed(5);
    },
    updateMinSalePrice(article) {
      for (const [
        singleListIndex,
        singleList,
      ] of article.defaultListPrices.entries()) {
        this.getFinalPrice(
          article,
          singleList.PORC_UTILID,
          true,
          singleListIndex
        );
      }
      for (const [
        singlePresentationIndex,
        singlePresentation,
      ] of article.PRESENTACIONES.entries()) {
        for (const [
          singlePresentationPriceIndex,
          singlePresentationPrice,
        ] of singlePresentation.defaultListPrices.entries()) {
          this.getFinalPrice(
            article,
            singlePresentationPrice.PORC_UTILID,
            true,
            singlePresentationPriceIndex,
            false,
            singlePresentation
          );
        }
      }
    },
    getFinalTaxPrice(article, priceList) {
      let finalPriceTaxes = Number(priceList.calculatedPrice);

      // Calculate discounts using watterfall method
      for (const singleDiscount of priceList.DESCUENTOS) {
        finalPriceTaxes -= Number(singleDiscount.CANTIDAD);
      }

      // Calculate costs using watterfall method after discounts calculation
      for (const singleCost of priceList.COSTOS) {
        finalPriceTaxes += Number(singleCost.CANTIDAD);
      }

      let finalPriceWithDeductions = finalPriceTaxes;

      if (article.CANT_IVA) {
        finalPriceTaxes += finalPriceWithDeductions * (article.CANT_IVA / 100);
      }

      if (article.CANT_IEPS) {
        finalPriceTaxes += finalPriceWithDeductions * (article.CANT_IEPS / 100);
      }

      return finalPriceTaxes;
    },
    startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación del inventario",
        message: `¿Desea confirmar la exportación de inventario a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    startExportFullArticle() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación del inventario",
        message: `¿Desea confirmar la exportación de inventario a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportFullArticleData(),
      });
    },
    exportData() {
      let articlesPrices = [];
      for (let singleArticle of this.articles) {
        // Fill object with needed values
        for (const singlePriceList of singleArticle.defaultListPrices) {
          // Fill presentationt object with needed fields
          for (const singlePresentation of singleArticle.PRESENTACIONES) {
            for (const singlePresentationPrice of singlePresentation.defaultListPrices) {
              let newObjectExport = {};

              newObjectExport["CLAVE DEL ARTICULO (Requerido)"] =
                singleArticle.CLAVE_ART;

              newObjectExport["CLAVE DE LA LISTA DE PRECIOS (Requerido)"] =
                singlePriceList.NOMBRE_LISTAPT;

              newObjectExport["ES PRESENTACION (Opcional)"] = "S";

              newObjectExport["NOMBRE DE LA PRESENTACION (Opcional)"] =
                singlePresentation.NOMBRE;

              newObjectExport["PORCENTAJE DE UTILIDAD (Requerido)"] =
                singlePresentationPrice.PORC_UTILID;

              newObjectExport["PRECIO NETO"] = round(
                this.getFinalTaxPrice(singleArticle, singlePresentationPrice),
                2
              );

              articlesPrices.push(newObjectExport);
            }
          }

          let newObjectExport = {};

          newObjectExport["CLAVE DEL ARTICULO (Requerido)"] =
            singleArticle.CLAVE_ART;

          newObjectExport["CLAVE DE LA LISTA DE PRECIOS (Requerido)"] =
            singlePriceList.NOMBRE_LISTAPT;

          newObjectExport["ES PRESENTACION (Opcional)"] = "N";

          newObjectExport["NOMBRE DE LA PRESENTACION (Opcional)"] = "";

          newObjectExport["PORCENTAJE DE UTILIDAD (Requerido)"] =
            singlePriceList.PORC_UTILID;

          newObjectExport["PRECIO NETO"] = round(
            this.getFinalTaxPrice(singleArticle, singlePriceList),
            2
          );

          articlesPrices.push(newObjectExport);
        }
      }
      let payload = {
        data: articlesPrices,
        name: "precios-de-articulos-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    exportFullArticleData() {
      let articlesPrices = [];
      for (let singleArticle of this.articles) {
        // Fill object with needed values

        let newObjectExport = {};

        newObjectExport["CLAVE DEL ARTICULO"] = singleArticle.CLAVE_ART;
        newObjectExport["DESCRIPCION"] = singleArticle.DESCRIPCION;

        newObjectExport["MARCA"] = singleArticle.CLAVE_MARCA
          ? singleArticle.CLAVE_MARCA
          : "";

        // Category fields of the article
        newObjectExport["FAMILIA"] = "";
        if (singleArticle.CLAVE_FAM) {
          for (const singleCategory of this.categories) {
            if (singleCategory._id === singleArticle.CLAVE_FAM) {
              newObjectExport["FAMILIA"] = singleCategory.CLAVE_FAM;
            }
          }
        }

        // All warehouses invetory
        newObjectExport["INVENTARIO TOTAL"] = singleArticle.totalQuantity;

        // Inventory per warehouse
        for (const singleWarehouse of this.warehouses) {
          newObjectExport[`INVENTARIO ${singleWarehouse.NOMBRE_ALM}`] = 0;
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            if (singleWarehouse._id === singleArticleWarehouse.CLAVE_ALMACEN) {
              newObjectExport[`INVENTARIO ${singleWarehouse.NOMBRE_ALM}`] =
                singleArticleWarehouse.CANTIDAD;
            }
          }
        }

        // Utility
        for (const singlePriceList of this.priceLists) {
          newObjectExport[`UTILIDAD ${singlePriceList.CLAVE_LISTAP}`] = 0;
          for (const singleArticlePriceList of singleArticle.defaultListPrices) {
            if (singlePriceList._id === singleArticlePriceList.CLAVE_LISTAP) {
              newObjectExport[`UTILIDAD ${singlePriceList.CLAVE_LISTAP}`] =
                singleArticlePriceList.PORC_UTILID;
            }
          }
        }

        // price without taxes
        for (const singlePriceList of this.priceLists) {
          newObjectExport[
            `PRECIO SIN IMPUESTOS ${singlePriceList.CLAVE_LISTAP}`
          ] = 0;
          for (const singleArticlePriceList of singleArticle.defaultListPrices) {
            if (singlePriceList._id === singleArticlePriceList.CLAVE_LISTAP) {
              newObjectExport[
                `PRECIO SIN IMPUESTOS ${singlePriceList.CLAVE_LISTAP}`
              ] = singleArticlePriceList.calculatedPrice;
            }
          }
        }

        newObjectExport["PORCENTAJE DE IVA (Requerido)"] =
          singleArticle.CANT_IVA ? singleArticle.CANT_IVA : 0;
        newObjectExport["PORCENTAJE DE IEPS (Requerido)"] =
          singleArticle.CANT_IEPS ? singleArticle.CANT_IEPS : 0;

        // Final price
        for (const singlePriceList of this.priceLists) {
          newObjectExport[`PRECIO NETO ${singlePriceList.CLAVE_LISTAP}`] = 0;
          for (const singleArticlePriceList of singleArticle.defaultListPrices) {
            if (singlePriceList._id === singleArticlePriceList.CLAVE_LISTAP) {
              newObjectExport[`PRECIO NETO ${singlePriceList.CLAVE_LISTAP}`] =
                round(
                  this.getFinalTaxPrice(singleArticle, singleArticlePriceList),
                  2
                );
            }
          }
        }

        newObjectExport["COSTO MINIMO (Requerido)"] =
          singleArticle.COSTO_MIN_VTA ? singleArticle.COSTO_MIN_VTA : 0;
        newObjectExport["CODIGO SAT (Requerido)"] = singleArticle.CLAVE_SAT_DESC
          ? singleArticle.CLAVE_SAT_DESC.split(" ")[0]
          : "";
        newObjectExport["UNIDAD DE MEDIDA BASE (Requerido)"] =
          singleArticle.UNIDAD_EMP ? singleArticle.UNIDAD_EMP.DESCRIP_MED : "";

        articlesPrices.push(newObjectExport);
      }
      let payload = {
        data: articlesPrices,
        name: "precios-de-articulos-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    openPriceListDicounts(articleInformation, priceList, presentation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddDiscount,
        props: {
          isPricesList: true,
          article: articleInformation._id,
          presentation: presentation ? presentation._id : undefined,
          item: priceList,
          priceList: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openPriceListCosts(articleInformation, priceList, presentation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCost,
        props: {
          isPricesList: true,
          article: articleInformation._id,
          presentation: presentation ? presentation._id : undefined,
          item: priceList,
          priceList: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    priceLists() {
      let columnsToAdd = [];
      for (const singleList of this.priceLists) {
        columnsToAdd.push({
          field: `lista_precios_${singleList.CLAVE_LISTAP}`,
          label: singleList.CLAVE_LISTAP,
          sortable: true,
          searchable: true,
          display: true,
        });
      }
      this.priceListsColumns = columnsToAdd;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.totalQuantity = 0;

        let inventoryWarehouses = [];
        let defaultListPrices = [];

        for (let singleListPresentation of articleWithProps.PRESENTACIONES) {
          singleListPresentation.defaultListPrices = [];
        }

        /**
         * Add default list prices
         */
        for (let singlePriceLists of this.priceLists) {
          let foundPriceList = false;

          //Searching in article price lists
          for (let singleArticlePriceList of singleArticle.LISTAS_PREC) {
            if (singlePriceLists._id === singleArticlePriceList.CLAVE_LISTAP) {
              // Calculate final price
              let finalPriceResult = this.getFinalPrice(
                singleArticle,
                singleArticlePriceList.PORC_UTILID
              );

              let formattedPriceList = {
                _id: singleArticlePriceList._id,
                CLAVE_LISTAP: singleArticlePriceList.CLAVE_LISTAP,
                NOMBRE_LISTAPT: singleArticlePriceList.NOMBRE_LISTAPT,
                PORC_UTILID: singleArticlePriceList.PORC_UTILID,
                COSTOS: singleArticlePriceList.COSTOS,
                DESCUENTOS: singleArticlePriceList.DESCUENTOS,
                calculatedPrice: finalPriceResult,
              };

              articleWithProps[
                `lista_precios_${singlePriceLists.CLAVE_LISTAP}`
              ] = this.getFinalTaxPrice(singleArticle, formattedPriceList);
              defaultListPrices.push(formattedPriceList);
              foundPriceList = true;
            }
          }

          //Searching in article presentation price lists
          for (let singleListPresentation of singleArticle.PRESENTACIONES) {
            let foundPriceListPresentation = false;
            if (singleListPresentation.LISTAS_PREC) {
              for (let singlePriceListPresentation of singleListPresentation.LISTAS_PREC) {
                if (
                  singlePriceLists._id ===
                  singlePriceListPresentation.CLAVE_LISTAP
                ) {
                  singleListPresentation.defaultListPrices.push({
                    _id: singlePriceListPresentation._id,
                    CLAVE_LISTAP: singlePriceListPresentation.CLAVE_LISTAP,
                    NOMBRE_LISTAPT: singlePriceListPresentation.NOMBRE_LISTAPT,
                    PORC_UTILID: singlePriceListPresentation.PORC_UTILID,
                    calculatedPrice: this.getFinalPrice(
                      singleArticle,
                      singlePriceListPresentation.PORC_UTILID,
                      false,
                      0,
                      false,
                      singleListPresentation
                    ),
                    COSTOS: singlePriceListPresentation.COSTOS,
                    DESCUENTOS: singlePriceListPresentation.DESCUENTOS,
                  });
                  foundPriceListPresentation = true;
                }
              }
            }

            //If article presentation price list was not found
            if (!foundPriceListPresentation) {
              if (singleArticle.COSTO_MIN_VTA) {
                let formatedPriceList = { ...singlePriceLists };
                formatedPriceList.NOMBRE_LISTAPT =
                  singlePriceLists.CLAVE_LISTAP;
                formatedPriceList.CLAVE_LISTAP = singlePriceLists._id;
                delete formatedPriceList._id;
                formatedPriceList.calculatedPrice = this.getFinalPrice(
                  singleArticle,
                  formatedPriceList.PORC_UTILID,
                  false,
                  0,
                  false,
                  singleListPresentation
                );
                formatedPriceList.COSTOS = [];
                formatedPriceList.DESCUENTOS = [];
                singleListPresentation.defaultListPrices.push(
                  formatedPriceList
                );
              }
            }
          }

          //If article price list was not found
          if (!foundPriceList) {
            let formatedPriceList = { ...singlePriceLists };
            let finalPriceResult = 0;

            if (singleArticle.COSTO_MIN_VTA) {
              finalPriceResult = this.getFinalPrice(
                singleArticle,
                formatedPriceList.PORC_UTILID
              );
            }

            formatedPriceList.NOMBRE_LISTAPT = singlePriceLists.CLAVE_LISTAP;
            formatedPriceList.CLAVE_LISTAP = singlePriceLists._id;
            delete formatedPriceList._id;
            formatedPriceList.calculatedPrice = finalPriceResult;
            formatedPriceList.COSTOS = [];
            formatedPriceList.DESCUENTOS = [];

            articleWithProps[`lista_precios_${singlePriceLists.CLAVE_LISTAP}`] =
              this.getFinalTaxPrice(singleArticle, formatedPriceList);
            defaultListPrices.push(formatedPriceList);
          }
        }

        // Get available items
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            inventoryWarehouses.push(singleArticleWarehouse);
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }

        articleWithProps.inventoryWarehouses = inventoryWarehouses;
        articleWithProps.defaultListPrices = defaultListPrices;

        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        return articleWithProps;
      });
    },
    filteredArticles() {
      if (this.filterProvider === "") {
        return this.articles.map((singleArticle) => {
          let articleWithProps = { ...singleArticle };
          return articleWithProps;
        });
      }
      return this.articles.filter((singleArticle) => {
        for (let singleProviderPrice of singleArticle.PRECIOS_PROV) {
          if (singleProviderPrice.CLAVE_PRV === this.filterProvider._id) {
            return true;
          }
        }
        return false;
      });
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      })
        .sort((a, b) => {
          return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
        })
        .sort((a, b) => {
          return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
        });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    brands() {
      return this.$store.getters.BRANDS;
    },
    categories() {
      /**
       * Map main categories with selected sub categories
       */
      return this.$store.getters.CATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES_ALL.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        if (singleWarehouseWithProps.STATUS === "AC") {
          singleWarehouseWithProps.STATUS_FOR = "Activo";
        }
        if (singleWarehouseWithProps.STATUS === "PE") {
          singleWarehouseWithProps.STATUS_FOR = "Desactivado";
        }
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        if (!singleWarehouse.TIPO || singleWarehouse.TIPO === "AL") {
          return true;
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
